import { useMemo } from 'react';
import * as React from 'react';

import { Skeleton, TableBody, TableCell, TableRow } from '@mui/material';

export const TripsTableRowSkeleton: React.FC = () => {
	const fakeData = useMemo(() => Array.from(Array(5).keys()), []);

	return (
		<TableBody>
			{fakeData.map((row) => (
				<TableRow key={row}>
					<TableCell colSpan={6}>
						<Skeleton variant="rectangular" width="100%" height={40} />
					</TableCell>
				</TableRow>
			))}
		</TableBody>
	);
};
