import * as React from 'react';

import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import {
	Box,
	Chip,
	FormControl,
	Input,
	InputLabel,
	MenuItem,
	Select,
	useMediaQuery,
} from '@mui/material';

import { statusChoices, StatusProps } from '@fllite-fe/shared/src/const/statusChoices';
import { muiTheme } from '@fllite-fe/shared/src/muiTheme';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
	PaperProps: {
		style: {
			maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
			// width: 250,
		},
	},
};

interface FilterByStatusProps {
	setSelectedStatuses: (selectedStatuses: StatusProps[]) => void;
	selectedStatuses: StatusProps[];
}

export const FilterByStatus: React.FC<FilterByStatusProps> = ({
	setSelectedStatuses,
	selectedStatuses,
}) => {
	const isDesktop = useMediaQuery(muiTheme.breakpoints.up('md'));

	const handleChange = ({ target: { value } }) => {
		setSelectedStatuses(
			(typeof value === 'string' ? value.split(',') : value).map((statusItem: string) => ({
				name: statusItem,
				id: statusChoices.find(({ name }) => name === statusItem)?.id,
			})),
		);
	};

	const handleDelete = (statusValue) => {
		setSelectedStatuses(selectedStatuses.filter((statusItem) => statusItem.name !== statusValue));
	};

	return (
		<div>
			<FormControl sx={{ m: 1, minWidth: isDesktop ? 300 : 210 }}>
				<InputLabel id="demo-multiple-chip-label">Filter by status</InputLabel>
				<Select
					labelId="demo-multiple-chip-label"
					id="demo-multiple-chip"
					multiple
					value={selectedStatuses.reduce((acc, statusItem) => [...acc, statusItem.name], [])}
					onChange={handleChange}
					// sx={{ border: '0 none' }}
					input={
						<Input
							id="select-multiple-chip"
							// sx={{ border: '0 none' }}
						/>
					}
					renderValue={(selected) => (
						<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
							{selected.map((statusItem) => (
								<Chip
									key={statusItem}
									label={statusItem}
									deleteIcon={
										// MUI bug https://github.com/mui/material-ui/issues/18658 - deleteIcon is not working
										<div onMouseDown={() => handleDelete(statusItem)}>
											<HighlightOffIcon />
										</div>
									}
									onDelete={() => handleDelete(statusItem)}
								/>
							))}
						</Box>
					)}
					MenuProps={MenuProps}
				>
					{statusChoices.map((statusItem) => (
						<MenuItem key={statusItem.id} value={statusItem.name}>
							{statusItem.name}
						</MenuItem>
					))}
				</Select>
			</FormControl>
		</div>
	);
};
