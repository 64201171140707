import { useApiOperatorBusinessCasesGetCollection } from '@fllite-fe/api';

import { MyTripsBusinessCase } from '../types';

interface CharterOperatorTripsProps {
	page?: number;
	itemsPerPage?: number;
	status?: string[];
}

export const useCharterOperatorTrips = ({
	page,
	itemsPerPage,
	status,
}: CharterOperatorTripsProps = {}) => {
	const response = useApiOperatorBusinessCasesGetCollection<MyTripsBusinessCase[]>(
		{
			page: page ?? 1,
			itemsPerPage: itemsPerPage ?? 10,
			status: (status as any) ?? [], // hell but bck has string and array options, so we need use any
		},
		{
			request: {
				withHydra: true,
			},
		},
	);

	const { isLoading } = response;
	const data = response.data?.['hydra:member'] ?? [];
	const totalItems = response.data?.['hydra:totalItems'] ?? 0;

	return {
		data,
		totalItems,
		isLoading,
	};
};
