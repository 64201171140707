import * as React from 'react';

import styled from '@emotion/styled';
import { Avatar, Stack, TableCell, TableRow, Typography } from '@mui/material';

const NoResultText = styled(Typography)(() => ({
	color: '#97989A',
}));

export const TripsNoResult: React.FC = () => (
	<TableRow>
		<TableCell colSpan={6}>
			<Stack direction="column" spacing={2} alignItems="center" padding={4}>
				<Avatar
					alt="No result"
					src="/assets/airplane.webp"
					sx={{ minWidth: 100, minHeight: 100 }}
				/>
				<NoResultText>No results found</NoResultText>
			</Stack>
		</TableCell>
	</TableRow>
);
