import { FC } from 'react';

import styled from '@emotion/styled';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Box, Chip, IconButton, Stack, TableRow, Typography } from '@mui/material';
import Link from 'next/link';

import { ProposalDateFromNow } from '@fllite-fe/shared/src/components/ProposalDateFromNow';
import { MyTripsBusinessCase } from '@fllite-fe/shared/src/types';
import { translateBusinessCaseStatusWithIcon } from '@fllite-fe/shared/src/utils/commonTrans';
import { formatDateWithTz } from '@fllite-fe/shared/src/utils/datetime';
import { getTripStatusColor } from '@fllite-fe/shared/src/utils/getTripStatusColor';

const StyledTableRow = styled(TableRow)(() => ({
	// hide last border
	borderBottom: '1px solid #DDDFE3',
	'&:last-child td, &:last-child th': {
		border: 0,
	},
}));

const Label = styled(Typography)`
	color: #97989a;
	font-size: 10px;
`;

interface TripRowMobileProps {
	tripItem: MyTripsBusinessCase;
}
const TripRowMobile: FC<TripRowMobileProps> = ({ tripItem }) => {
	const { departureAt } = tripItem.inquiry.inquiryLegs[0];
	const { timeZone } = tripItem.inquiry.inquiryLegs[0].departureAirport;
	const departureDayReadable = formatDateWithTz(departureAt, timeZone, 'LL h:mm A z');

	return (
		<StyledTableRow key={tripItem.id}>
			<Box padding="18px 24px">
				<Stack direction="row" spacing={2} justifyContent="space-between">
					<Box>
						<Box mb={1}>
							<Label>Trip number</Label>
							<Typography fontSize="14px">{tripItem.tripNumber}</Typography>
						</Box>
						<Box mb={1}>
							<Label>From ➞ Destination</Label>
							<Box>
								<Typography sx={{ display: 'flex', alignItems: 'center' }}>
									{tripItem.inquiry.inquiryLegs[0].departureAirport.airportCode}
									<ArrowRightAltIcon />
									{tripItem.inquiry.inquiryLegs[0].destinationAirport.airportCode}
								</Typography>
								<Typography color="#97989A" fontSize="12px" fontWeight={500}>
									{tripItem.inquiry.flightType.name}
								</Typography>
							</Box>
						</Box>
						<Box mb={1}>
							<Label>Departure time & date</Label>
							<Typography fontSize="14px">{departureDayReadable}</Typography>
						</Box>
						<Box mb={1}>
							<ProposalDateFromNow {...{ departureAt }} dateTimezone={timeZone} />
						</Box>
						<Box mb={1}>
							<Chip
								color={getTripStatusColor(tripItem.status, tripItem.contractSignStatus)}
								label={translateBusinessCaseStatusWithIcon(tripItem)}
							/>
						</Box>
					</Box>
					<Box mr={4}>
						<Link href={`/trip/${tripItem.id}`} legacyBehavior>
							<IconButton>
								<NavigateNextIcon color="primary" fontSize="large" />
							</IconButton>
						</Link>
					</Box>
				</Stack>
			</Box>
		</StyledTableRow>
	);
};

export default TripRowMobile;
