import * as React from 'react';
import { useState } from 'react';

import {
	Table,
	TableBody,
	TableCell,
	TableFooter,
	TableHead,
	TablePagination,
	TableRow,
	useMediaQuery,
} from '@mui/material';

import { StatusProps } from '@fllite-fe/shared/src/const/statusChoices';
import { useCharterOperatorTrips } from '@fllite-fe/shared/src/hooks/useCharterOperatorTrips';
import { muiTheme } from '@fllite-fe/shared/src/muiTheme';

import { FilterByStatus } from './FilterByStatus';
import {
	AdvancedFilters,
	Container,
	TripListContainer,
	TripListHeader,
	TripListTitle,
} from './styles';
import TripRow from './TripRow';
import TripRowMobile from './TripRowMobile';
import { TripsNoResult } from './TripsNoResult';
import { TripsTableRowSkeleton } from './TripsTableRowSkeleton';

const ROWS_PER_PAGE = [10, 50, 100];

const Trips: React.FC = () => {
	const [pageIndex, setPageIndex] = useState(0);
	const [itemsPerPage, setItemsPerPage] = useState(ROWS_PER_PAGE[0]);
	const [selectedStatuses, setSelectedStatuses] = useState<StatusProps[]>([]);

	const isDesktop = useMediaQuery(muiTheme.breakpoints.up('md'));

	const { data, isLoading, totalItems } = useCharterOperatorTrips({
		page: pageIndex + 1, // mui count from 0, but bck count from 1
		itemsPerPage,
		status: selectedStatuses.reduce((acc, statusItem) => [...acc, statusItem.id], []),
	});

	const handleChangePage = (event, newPageIndex) => {
		event.preventDefault();
		setPageIndex(newPageIndex);
	};

	const handleChangeRowsPerPage = (event) => {
		setItemsPerPage(event.target.value);
	};

	const noResult = data.length === 0;

	return (
		<Container>
			<TripListContainer>
				<TripListHeader>
					<TripListTitle>Trip list</TripListTitle>

					<AdvancedFilters>
						<FilterByStatus {...{ setSelectedStatuses, selectedStatuses }} />
					</AdvancedFilters>
				</TripListHeader>

				<Table>
					{isDesktop ? (
						<>
							<TableHead>
								<TableRow>
									<TableCell>Trip number</TableCell>
									<TableCell>From - Destination</TableCell>
									<TableCell>Departure time &amp; date</TableCell>
									<TableCell>Days until trip</TableCell>
									<TableCell>Status</TableCell>
									<TableCell />
								</TableRow>
							</TableHead>
							{isLoading ? (
								<TripsTableRowSkeleton />
							) : noResult ? (
								<TripsNoResult />
							) : (
								<TableBody>
									{data &&
										data.map((tripItem) => <TripRow key={tripItem.id} tripItem={tripItem} />)}
								</TableBody>
							)}
						</>
					) : (
						<TableBody>
							{data &&
								data.map((tripItem) => <TripRowMobile key={tripItem.id} tripItem={tripItem} />)}
						</TableBody>
					)}

					{!isLoading && !noResult && (
						<TableFooter>
							<TableRow>
								<TablePagination
									rowsPerPageOptions={[10, 25, 50]}
									colSpan={6}
									count={totalItems}
									rowsPerPage={itemsPerPage}
									page={pageIndex}
									SelectProps={{
										inputProps: {
											'aria-label': 'rows per page',
											value: itemsPerPage,
										},
										native: false,
									}}
									onPageChange={handleChangePage}
									onRowsPerPageChange={handleChangeRowsPerPage}
								/>
							</TableRow>
						</TableFooter>
					)}
				</Table>
			</TripListContainer>
		</Container>
	);
};

export default Trips;
