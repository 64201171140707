import { FC } from 'react';

import styled from '@emotion/styled';

import { add, formatDateWithTz, fromNow, isSame } from '@fllite-fe/shared/src/utils/datetime';

import { Icon } from './Icon';

interface ProposalDateFromNowProps {
	departureAt: string;
	dateTimezone: string;
}

const FromNowStyled = styled('span')<{ isHighlighted?: boolean }>`
	display: flex;
	flex-direction: row;
	font-size: 11px;
	color: ${({ isHighlighted }) => (isHighlighted ? '#353535' : '#97989A')};
`;

const StyledIcon = styled(Icon)`
	margin-right: 4px;
	display: flex;
	align-items: center;
`;

export const ProposalDateFromNow: FC<ProposalDateFromNowProps> = ({
	departureAt,
	dateTimezone,
}) => {
	const timeFromNow = fromNow(departureAt, dateTimezone);
	const isToday = isSame(formatDateWithTz(departureAt, dateTimezone), new Date(), 'day');
	const isTomorrow = isSame(add(formatDateWithTz(departureAt, dateTimezone), 1, 'day'), undefined);

	return (
		<FromNowStyled isHighlighted={isToday || isTomorrow}>
			{isToday ? (
				<StyledIcon type="time-blue-3" />
			) : isTomorrow ? (
				<StyledIcon type="time-orange" />
			) : (
				<></>
			)}{' '}
			{timeFromNow}
		</FromNowStyled>
	);
};
