import { translateBusinessCaseStatus } from '../utils/commonTrans';

export interface StatusProps {
	name: string;
	id: string;
}

export const statusChoices = [
	{ id: 'inquiry_received' },
	{ id: 'automatic_proposal_requested' },
	{ id: 'manual_proposal_requested' },
	{ id: 'proposal_created' },
	{ id: 'proposal_sent' },
	{ id: 'proposal_approved' },
	{ id: 'trip_booked' },
	{ id: 'awaiting_payment' },
	{ id: 'paid' },
	{ id: 'canceled' },
	{ id: 'expired' },
	{ id: 'fulfilled' },
].map((choice) => ({
	...choice,
	name: translateBusinessCaseStatus(undefined, choice.id, true),
})) as StatusProps[];
