import styled from '@emotion/styled';

export const Container = styled('div')`
	max-width: 100vw;
	overflow-x: hidden;
`;

export const TripListContainer = styled('div')`
	margin: 24px 0;
	background: #ffffff;
	box-shadow: 0px 4px 30px rgba(53, 53, 53, 0.06);
	border-radius: 8px;
`;

export const TripListHeader = styled('div')`
	padding-top: 12px;
	padding-bottom: 24px;
	border-bottom: 1px solid #dddfe3;

	${({ theme }) => theme.breakpoints.up('md')} {
		display: grid;
		grid-template-columns: 1fr 2.5fr;
		margin-bottom: 24px;
		grid-gap: 16px;
	}
`;

export const TripListTitle = styled('div')`
	margin-left: 30px;
	display: flex;
	align-items: center;
	font-weight: 600;
	font-size: 20px;
	line-height: 32px;
	color: #0056be;
`;

export const AdvancedFilters = styled('div')`
	display: flex;
	padding-left: 18px;
	padding-right: 28px;

	${({ theme }) => theme.breakpoints.up('md')} {
		justify-content: flex-end;
		padding-left: 0;
		padding-top: 20px;
	}
`;
