import { FC } from 'react';

import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import {
	Chip,
	IconButton,
	styled,
	TableCell,
	tableCellClasses,
	TableRow,
	Typography,
} from '@mui/material';
import Link from 'next/link';

import { ProposalDateFromNow } from '@fllite-fe/shared/src/components/ProposalDateFromNow';
import { MyTripsBusinessCase } from '@fllite-fe/shared/src/types';
import { translateBusinessCaseStatusWithIcon } from '@fllite-fe/shared/src/utils/commonTrans';
import { formatDateWithTz } from '@fllite-fe/shared/src/utils/datetime';
import { getTripStatusColor } from '@fllite-fe/shared/src/utils/getTripStatusColor';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
	[`&.${tableCellClasses.head}`]: {
		backgroundColor: theme.palette.common.black,
		color: theme.palette.common.white,
	},
	[`&.${tableCellClasses.body}`]: {
		fontSize: 14,
	},
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
	'&:nth-of-type(odd)': {
		backgroundColor: theme.palette.action.hover,
	},
	// hide last border
	'&:last-child td, &:last-child th': {
		border: 0,
	},
}));

interface TripRowProps {
	tripItem: MyTripsBusinessCase;
}
const TripRow: FC<TripRowProps> = ({ tripItem }) => {
	const { departureAt } = tripItem.inquiry.inquiryLegs[0];
	const { timeZone } = tripItem.inquiry.inquiryLegs[0].departureAirport;
	const departureDayReadable = formatDateWithTz(departureAt, timeZone, 'LL h:mm A z');

	return (
		<StyledTableRow key={tripItem.id}>
			<StyledTableCell>{tripItem.tripNumber}</StyledTableCell>
			<StyledTableCell>
				<Typography sx={{ display: 'flex', alignItems: 'center' }}>
					{tripItem.inquiry.inquiryLegs[0].departureAirport.airportCode}
					<ArrowRightAltIcon />
					{tripItem.inquiry.inquiryLegs[0].destinationAirport.airportCode}
				</Typography>
				<Typography>{tripItem.inquiry.flightType.name}</Typography>
			</StyledTableCell>
			<StyledTableCell>{departureDayReadable}</StyledTableCell>
			<StyledTableCell>
				<ProposalDateFromNow {...{ departureAt }} dateTimezone={timeZone} />
			</StyledTableCell>
			<StyledTableCell>
				<Chip
					color={getTripStatusColor(tripItem.status, tripItem.contractSignStatus)}
					label={translateBusinessCaseStatusWithIcon(tripItem)}
				/>
			</StyledTableCell>
			<StyledTableCell>
				<Link href={`/trip/${tripItem.id}`} legacyBehavior>
					<IconButton>
						<NavigateNextIcon />
					</IconButton>
				</Link>
			</StyledTableCell>
		</StyledTableRow>
	);
};

export default TripRow;
